import React, { Component } from 'react'
import { navigate } from 'gatsby'

import InfoBox from '../../info-box'

class Existing extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleSubmit = (value) => {
    this.props.submitData({ existing: value })
    if (this.props.landing) {
      let path = '/apply/coverage'
      this.props.toggleBypassZip(true)
      this.props.toggleIncludeAddress(true)
      this.props.nextStep('zip')
      navigate(path)
    } else {
      this.props.handleNext('existing')
    }
  }

  render = () => {
    const { landing } = this.props
    return (
      <div>
        {!landing && (
          <InfoBox text="Great! First, just answer a few simple questions for us." />
        )}
        <strong className="h4">{this.props.title || 'Are you currently insured?'}</strong>
        <div className="row">
          <div className="col full-width inline-buttons">
            <button
              className="btn"
              onClick={() => {
                this.handleSubmit('Yes')
              }}
            >
              Yes
            </button>
            <button
              className="btn"
              onClick={() => {
                this.handleSubmit('No')
              }}
            >
              No
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default Existing
